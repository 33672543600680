@font-face {
  font-family: "Alternate Gothic Pro No One";
  src: url("../assets/fonts/AlternateGothicPro-NoOne.eot");
  src: url("../assets/fonts/AlternateGothicPro-NoOne.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AlternateGothicPro-NoOne.woff2") format("woff2"),
    url("../assets/fonts/AlternateGothicPro-NoOne.woff") format("woff"),
    url("../assets/fonts/AlternateGothicPro-NoOne.ttf") format("truetype"),
    url("../assets/fonts/AlternateGothicPro-NoOne.svg#AlternateGothicPro-NoOne")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../assets/fonts/RobotoMono-Regular.eot");
  src: url("../assets/fonts/RobotoMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/RobotoMono-Regular.woff2") format("woff2"),
    url("../assets/fonts/RobotoMono-Regular.woff") format("woff"),
    url("../assets/fonts/RobotoMono-Regular.ttf") format("truetype"),
    url("../assets/fonts/RobotoMono-Regular.svg#RobotoMono-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--ra-red);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-align: center;
  font-size: 15vw;
  line-height: 15vw;
  text-transform: uppercase;
}

h2 {
  text-align: center;
  font-size: 4vw;
  line-height: 3.8vw;
  text-transform: uppercase;
  letter-spacing: -0.2vw;
}

h3 {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-align: center;
  font-size: 7vw;
  line-height: 5.5vw;
  text-transform: uppercase;
}

h4 {
  font-size: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.alt-font {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-transform: uppercase;
}

@include mobile {
  
  html,
  body {
    font-size: 12px;
    line-height: 14px;
  }

  h1 {
    font-size: 23vw;
    line-height: 23vw;
  }

  h2 {
    font-size: 7vw;
    line-height: 6vw;
  }

  h3 {
    font-size: 12vw;
    line-height: 10vw;
  }

}
