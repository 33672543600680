.paragraph{
    width: 60vw;
    max-width: 650px;
    display: block;
    margin: 30px auto;
    &:last-child{
        padding-bottom: 150px;
    }
}

@include mobile{

    .paragraph{
        width: calc( 100% - 40px);
        max-width: none;
        display: block;
        margin: 30px 20px;
        &:last-child{
            padding-bottom: 150px;
        }
    }
    
}