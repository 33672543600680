.spotify{
    &__wrapper{
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 2;
        iframe{
            -webkit-box-shadow: 0px 0px 15px 1px rgba(229,47,40,1);
            -moz-box-shadow: 0px 0px 15px 1px rgba(229,47,40,1);
            box-shadow: 0px 0px 15px 1px rgba(229,47,40,1);
            border-radius: 12px;            
        }

    }

    &__button{
        position: fixed;
        bottom: 60px;
        left: 20px;
        z-index: 100;
        padding: 6px;
        border-radius: 50%;
        background-color: #FFF;
        display: inline-block;
        border: 1px solid var(--ra-red);
        -webkit-box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
        -moz-box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
        box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
    }

    &__icon{
        pointer-events: none;
        width: 20px;
        height: 20px;
        display: block;
    }
}