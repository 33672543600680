@keyframes bounce {
    0% {
      top: -20px;
    }
    100% {
      top: 20px;
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;

    }
    50%{
      opacity: 0;
    }
    100% {
      opacity: 1;

    }
  }
  