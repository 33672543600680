

*::selection {
  background: var(--ra-red);
  color: #FFF;
}
*::-moz-selection {
  background: var(--ra-red);
  color: #FFF;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
*::-webkit-scrollbar-track {
  background-color: #FFF;
}

*::-webkit-scrollbar-thumb {
  background: var(--ra-red);
  border: none;
}


html,
body {
  width: 100%;
  height: 100%;
}
