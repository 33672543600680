.nav{

    &__wrapper{
        margin: 20px auto;
        text-align: center;
        position: sticky;
        z-index: 10;
        pointer-events: none;
        &.top{
            top: 20px;
        }

        &.bottom{
            top: calc(100% - 54px);
        }
    }

    &__tablet,
    &__published{
        background-color: #FFF;
        padding: 7px 30px;
        border: 1px solid var(--ra-red);
        display: inline-block;
        border-radius: 30px;
        pointer-events: all;
        -webkit-box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
        -moz-box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
        box-shadow: 0px 0px 10px 1px rgba(229,47,40,0.6);
    }

    &__published{
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        padding:3px 30px;
        transform: translateX(-50%);
        border-top: none;
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 5px 1px 1px rgba(229,47,40,0.6);
        -moz-box-shadow: 0px 5px 5px 1px rgba(229,47,40,0.6);
        box-shadow: 0px 5px 10px 1px rgba(229,47,40,0.3);
    }

}

@include mobile {

    .nav{

        &__wrapper{
            width: calc(100% - 40px);
            padding: 0 20px;
            &.top{
                top: 20px;
            }
    
            &.bottom{
                top: calc(100% - 54px);
            }
        }


    &__tablet,
    &__published{
        display: block;
    }

    }

}