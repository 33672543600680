.credits {
  &__wrapper {
    width: 100%;
    min-height: calc(var(--app-height) - 100px);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ra-red);
    border-radius: 30px 30px 0 0;
    text-align: center;
    color: #fff;
    overflow: hidden;
    position: relative;
  }

  &__title {
    margin-bottom: 10px;
  }
  &__credit-wrapper {
    color: var(--ra-red);
    background-color: #fff;
    padding: 7px 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
    width: 30%;
    margin: 10px calc(35% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    border: 1px solid #fff;
    z-index: 1;
    @include hover {
      background-color: transparent;
      color: #fff;
      box-shadow: none;
    }
  }

  &__logo-wrapper {
    width: 15vw;
    height: auto;
    aspect-ratio: 2/1;
    position: absolute;
    left: 0;
    z-index: 10;
    margin: 20px;
  }


  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__back-button {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    animation: pulse 1s ease-in-out infinite;
    border: 1px solid #FFF;
    border-radius: 30px;
  }
}

@include mobile {

  .credits {
    &__wrapper {
      padding: 100px 0 150px 0;
      border-radius: 10px 10px 0 0;
    }
  

    &__credit-wrapper {
      width: calc(100% - 100px);
      margin: 10px 20px;
    }
  
    &__logo-wrapper {
      width: calc(100% - 40px);
      aspect-ratio: inherit;     
      position: absolute;
      top: 20px !important;
      left: 0;
      z-index: 10;
      margin: 20px;
    }
  
  
    &__logo {
      display: none;
    }
  
    &__back-button {
      padding: 7px 30px;
    }
  }
  
}