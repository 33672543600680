.intro {
  &__container {
    width: 100%;
    height: var(--app-height);
    margin-bottom: 50px;
    background-color: #00ff0010;
    position: relative;
    overflow: hidden;
  }

  &__logo-wrapper{
    display: none;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__line-wrapper {
    position: absolute;
    height: 0;
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    transition: height 0.4s ease-out;
    &.active {
      height: calc(100% - 40px);
    }
    pointer-events: none;
  }

  &__line {
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: rotate(45deg);
    transition: transform 0.2s ease-out;
    pointer-events: none;
    &.active {
      transform: rotate(0deg);
    }
    &::before {
      content: "";
      height: 200%;
      width: calc(50% - 2.5px);
      position: absolute;
      top: -50%;
      left: 0;
      border-right: 5px solid var(--ra-red);
    }
  }

  &__text-container {
    height: 100%;
    position: absolute;
    top: 0;
    width: 0;
    overflow: hidden;
    transition: width 1.5s ease-out;
    pointer-events: none;
    &.active {
      width: 50%;
    }
    &.left {
      right: 50%;
    }
    &.right {
      left: 50%;
    }
  }

  &__text {
    position: absolute;
    width: 100vw;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    text-shadow: 0px 0px 5px var(--ra-red);
    &.left {
      right: -50vw;
    }
    &.right {
      left: -50vw;
    }
  }

  &__text-sub{
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    &.active{
      opacity: 1;
      pointer-events: all;
    }
  }
}


@include mobile {

  .intro{

    &__logo-wrapper{
      display: block;
      position: absolute;
      top: 20px;
      width: 30vw;
      left: 35vw;
      height: auto;
      z-index: 10;
    }
    &__logo{
      width: 100%;
    }

    &__line-wrapper {
      position: absolute;
      width: 100vw;
      height: 0;
      aspect-ratio: inherit;
      &.active {
        height: 100vh;
      }
      pointer-events: none;
    }

    &__line {
      &::before {
        height: 70%;
        width: calc(50% - 1px);
        top: 15%;
        border-right: 2px solid var(--ra-red);
      }
    }
  }
}